@import './base.scss';

#vanta-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.vanta-canvas {
    filter: brightness(1.2) contrast(1.2) saturate(1.2);
    image-rendering: pixelated;
}

.hero-bg-grid {
    background: url(./images/gridtile.png);
    background-size: 9000% 9.2px;
    opacity: 0.05;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.hero-bg-gradient {
    background: linear-gradient(180deg, transparentize($background, 1), transparentize($background, 0.7), transparentize($background, 0.5), transparentize($background, 0.35), transparentize($background, 0.2), $background);
    background-repeat: no-repeat;
    background-position-y: 101%;
    background-size: auto 70%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.hero-body {

    // Fix gap in background rendering
    margin-bottom: -1px;

    h1 {
        text-shadow: 3px 4px 20px transparentize($background, 0.5);
    }

    p {
        color: $text-primary;
        text-shadow: 2px 3px 8px transparentize($background, 0.5);
    }
}

.chevron-down {
    $padding: 16px;

    position: absolute;
    left: calc(50% - #{$padding + 12px});
    bottom: -20px;

    button { 
        padding: $padding;
        background-color: transparent;
        border: none;
        cursor: pointer;
    
        &:hover {
            transform: translateY(6px);
            transition-duration: 0.5s;
        } 

        &:hover svg path {
            fill: $text-secondary;
            transition: fill 800ms;
        } 
    }
    
}

.hero-foot {

    @include desktop {
        .level-item:not(:first-child) {
            margin-left: 24px;
        }
    }

    a {
        height: 1.5em;
    }
}

.projectitem {
    p:not(:last-child) {
        margin-bottom: 12px;
    }
}

.portfolio-image {
    border-radius: 16px;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px)
    }

    to {
        opacity: 1;
        transform: none
    }
}

@for $i from 0 to 5 {
    .fadein-#{$i + 1} {
        -webkit-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-fill-mode: both;
        animation-duration: 1s;
        animation-delay: 0.8s + 0.4s * $i;
    }
}
