// Set your brand colors
$text-primary: #fff;
$text-secondary: #bbb;
$background: #111;
$accent: #fff;

// Update Bulma's global variables
$family-sans-serif: 'Jetbrains Mono', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$family-bold: 'Jetbrains Mono', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$family-heavy: 'Jetbrains Mono', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$primary: $accent;
$link: $text-primary;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $background;
$body-color: $text-secondary;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$link-hover: $text-secondary;

$body-size: 24px;
$title-color: $text-primary;
$title-family: $family-bold;
$subtitle-color: $text-primary;
$subtitle-family: $family-heavy;

html {
    scroll-behavior: smooth;
    font-display: block;
}

.mt-8 {
    margin-top: 2rem !important;
}

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/elements/title.sass';
@import '../node_modules/bulma/sass/elements/content.sass';
@import '../node_modules/bulma/sass/grid/columns.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
@import '../node_modules/bulma/sass/components/level.sass';
@import '../node_modules/bulma/sass/helpers/spacing.sass';
@import '../node_modules/bulma/sass/helpers/typography.sass';
@import '../node_modules/bulma/sass/helpers/visibility.sass';