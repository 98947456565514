@import './base.scss';

a { display: inline-block; }
  
p a:after {
    content: "";
    display: block;
    height: 2px;
    width: 102%;
    background: transparentize($color: $primary, $amount: 0.2);
    margin-top: -3px;
    margin-left: -1%;
}

a:hover {
    transition-duration: 0.3s;
}

p a:hover:after {
    background: $text-secondary;
}

::selection {
    color: $background;
    background-color: $text-primary;
}

a {
    -webkit-tap-highlight-color: transparent;
}